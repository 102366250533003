import React, { useState } from 'react';
import RegisterStepEmailEnter from 'pages/AuthPage/RegisterPage/_steps/RegisterStepEmailEnter';
import RegisterStepEmailConfirm from 'pages/AuthPage/RegisterPage/_steps/RegisterStepEmailConfirm';
import { Box, makeStyles } from '@material-ui/core';
import { xs } from 'shared/themeInstance';
import { useRegisterConfirmTimer } from 'pages/AuthPage/RegisterPage/_hooks/useRegisterConfirmTimer';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppDispatch, RootState } from '../../../_redux/store';
import { useCheckRegisterCodeMutation, useRegisterMutation } from '../../../_services/authApi';
import { fetchRequestRegisterEmailCode } from '../../../_redux/register/actions';
import { yaTarget } from '../../../utils/yaTarget';
import { authenticateWithToken } from '../../../_redux/appSlice';
import { RegistrationStepUserInfo } from './_steps/RegistrationStepUserInfo';
import { FormDataBuilder } from 'models/FormDataBuilder';
import { doRequest } from 'hooks/doRequest';

const MAX_ATTEMPTS = 500000;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    width: '100%',
  },
  contentContainer: {
    width: '100%',
    maxWidth: 680,
    borderRadius: 8,
    marginRight: 'auto',
    [xs]: {
      boxShadow: 'none',
      padding: 0,
      maxWidth: 'initial',
    },
  },
}));

enum Steps {
  initialEmailEnter,
  initialEmailConfirm,
  vkEmailEnter,
  vkEmailConfirm,
  userInfoEnter,
}

const RegisterPage = () => {
  const classes = useStyles();
  const [triggerRegister] = useRegisterMutation();
  const [triggerCheckRegisterCode] = useCheckRegisterCodeMutation();
  const dispatch: AppDispatch = useDispatch();
  const { register } = useSelector((root: RootState) => root);
  const { vkIdKey, ambassadorReferralId } = register;
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  // @ts-ignore
  const [state, setState] = useState({
    step: Steps.initialEmailEnter,
    codeSubmitAttemptCount: 0,
    codeRequestLoading: false,
    userdataFormLoading: false,
  });

  const { step, codeRequestLoading, codeSubmitAttemptCount, userdataFormLoading } = state;

  const { attemptCount, remainingTime, requestAttempt, resetConfirmTimer } =
    useRegisterConfirmTimer({
      maxAttempts: MAX_ATTEMPTS,
      attemptsInterval: 60 * 1000,
    });

  const emailFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    onSubmit: () => handleEmailSubmit(Steps.initialEmailConfirm, emailFormik.values.email),
  });

  const emailCodeSubmitFormik = useFormik({
    initialValues: {
      code: '',
    },
    validationSchema: yup.object().shape({
      code: yup.string().required(),
    }),
    onSubmit: async () => {
      try {
        const { success } = await doRequest(() =>
          triggerCheckRegisterCode({
            email: emailFormik.values.email,
            code: emailCodeSubmitFormik.values.code,
          }).unwrap(),
        );
        if (success) {
          setStep(Steps.userInfoEnter);
        }
        yaTarget('emailvalid');
      } catch {}
    },
  });

  const userInfoSubmitFormik = useFormik({
    initialValues: {
      last: '',
      first: '',
      middle: '',
      phone: '',
      birthDate: null,
    },
    onSubmit: () => handleConfirmSubmit(),
    validationSchema: yup.object({
      last: yup.string().required('Поле обязательно для заполнения'),
      first: yup.string().required('Поле обязательно для заполнения'),
      phone: yup.string().required('Поле обязательно для заполнения'),
      birthDate: yup
        .mixed()
        .required('Поле обязательно для заполнения')
        .test('is-valid-age', 'Укажите актуальный возраст', (value) => {
          const minDate = new Date();
          minDate.setFullYear(minDate.getFullYear() - 100);

          const maxDate = new Date();
          maxDate.setFullYear(maxDate.getFullYear() - 10);

          return value >= minDate && value <= maxDate;
        }),
    }),
    validateOnMount: true,
  });

  const setStep = (step) => {
    setState((prevState) => ({ ...prevState, step }));
  };

  const handleEmailSubmit = async (step: Steps, email: string) => {
    try {
      await requestAttempt();

      setState((state) => ({ ...state, codeRequestLoading: true }));
      const { payload } = await dispatch(fetchRequestRegisterEmailCode({ email }));

      if (payload.success) {
        setStep(step);

        yaTarget('registr');
      } else {
        resetConfirmTimer();
      }
      setState((state) => ({ ...state, codeRequestLoading: false }));
    } catch (err) {
      resetConfirmTimer();
      setState((state) => ({ ...state, codeRequestLoading: false }));
    }
  };

  const handleCodeRequest = (email: string) => {
    if (remainingTime) {
      return;
    }

    requestAttempt().then(() => {
      dispatch(fetchRequestRegisterEmailCode({ email }));

      if (attemptCount > 0) {
        toast('Новый код отправлен на почту', { type: 'success' });
      }
    });
  };

  const handleConfirmSubmit = async () => {
    const userData = {
      user: {
        name: {
          last: userInfoSubmitFormik.values.last,
          first: userInfoSubmitFormik.values.first,
          middle: userInfoSubmitFormik.values.middle,
        },
        phone: userInfoSubmitFormik.values.phone,
        birthDate: userInfoSubmitFormik.values.birthDate.format('DD.MM.YYYY'),
      },
    };

    const otherData = vkIdKey
      ? {
          email: emailFormik.values.email,
          code: emailCodeSubmitFormik.values.code,
          vk_id_key: vkIdKey,
        }
      : {
          email: emailFormik.values.email,
          code: emailCodeSubmitFormik.values.code,
        };

    const {
      data: { token },
    } = await doRequest(() =>
      triggerRegister(
        FormDataBuilder.create({
          ambassador: ambassadorReferralId,
          ...userData,
          ...otherData,
        }),
      ).unwrap(),
    );
    yaTarget('regbase');

    if (token) {
      toast('Ваш пароль был отправлен на email', { type: 'success' });
      yaTarget('reg-email');

      dispatch(
        authenticateWithToken({
          token,
        }),
      );
      navigate(urlParams.get('returnUrl') || '/lk/profile');
    } else {
      setState((prevState) => ({
        ...prevState,
        codeSubmitAttemptCount: codeSubmitAttemptCount + 1,
      }));
    }
  };

  const renderStep = () => {
    switch (step) {
      case Steps.initialEmailEnter:
        return <RegisterStepEmailEnter loading={codeRequestLoading} formik={emailFormik} />;
      case Steps.initialEmailConfirm:
        return (
          <RegisterStepEmailConfirm
            formik={emailCodeSubmitFormik}
            emailRequestAttemptCount={attemptCount}
            maxEmailRequestAttempts={MAX_ATTEMPTS}
            codeSubmitAttemptCount={codeSubmitAttemptCount}
            remainingTime={remainingTime}
            onCodeRequest={() => handleCodeRequest(emailFormik.values.email)}
          />
        );
      case Steps.userInfoEnter:
        return <RegistrationStepUserInfo formik={userInfoSubmitFormik} />;

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.contentContainer}>
        <Box>{renderStep()}</Box>
      </div>
    </div>
  );
};

export default RegisterPage;
