import React, { Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import QrComponent from 'pages/QrComponent/QrComponent';
import FooterMain from 'components/FooterMain/FooterMain';
import AuthTeachbase from 'pages/AuthTeachbase/AuthTeachbase';
import PublicGeneralReport from 'pages/PublicGeneralReport/PublicGeneralReport';
import PreAccelerationAuth from 'pages/PreAccelerationAuth/PreAccelerationAuth';
import { Box } from '@material-ui/core';
import AuthPage from 'pages/AuthPage/AuthPage';
import Navbar from 'components/Navbar/Navbar';
import { ScreenLoader } from 'shared/ScreenLoader';
import RouterProviderLayout from 'RouterProviderLayout';

const LkPage = React.lazy(() => import('./pages/LkPage/LkPage'));
const AdminPage = React.lazy(() => import('./pages/AdminPage/AdminPage'));
const LandingPage = React.lazy(() => import('./pages/LandingPage/LandingPage'));
const PublicTestResult = React.lazy(() => import('./pages/PublicTestResult/PublicTestResult'));

const RedirectToPdf = () => {
  useEffect(() => {
    window.location.href = '/winners.pdf';
  }, []);

  return <ScreenLoader fullScreen />;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Suspense fallback={<ScreenLoader fullScreen />}>
        <RouterProviderLayout />
      </Suspense>
    ),
    children: [
      { path: '/', element: <LandingPage /> },
      {
        path: 'auth/*',
        element: (
          <Box display='flex' flex={1} justifyContent='center' overflow='auto'>
            <AuthPage />
          </Box>
        ),
      },
      { path: '/winners', element: <RedirectToPdf /> }, // Редирект на файл winners.pdf
      { path: 'lk/*', element: <LkPage /> },
      { path: 'admin/*', element: <AdminPage /> },
      { path: 'qr', element: <QrComponent /> },
      { path: 'teachbase_auth', element: <AuthTeachbase /> },
      { path: 'preinc_auth', element: <PreAccelerationAuth /> },
      {
        path: 'results/*',
        element: (
          <div className='child-first:bg-black-gray'>
            <Navbar />
            <Routes>
              <Route path=':result_id' element={<PublicTestResult />} />
              <Route path='summary/:user_id' element={<PublicGeneralReport />} />
            </Routes>
            <FooterMain />
          </div>
        ),
      },
      { path: '*', element: <Navigate to='/' replace /> },
    ],
  },
]);

const AppRoutes = () => {
  return <RouterProvider router={router} fallbackElement={<ScreenLoader fullScreen />} />;
};

export default AppRoutes;
