import { Box, makeStyles } from '@material-ui/core';
import { AuthContext } from 'context/AuthContext/AuthContext';
import { doRequest } from 'hooks/doRequest';
import React, { useContext } from 'react';
import Button from 'shared/Button/Button';
import { LkContext } from 'context/LkContext/LkContext';
import { useDispatch, useSelector } from 'react-redux';
import { xs } from 'shared/themeInstance';
import { AuthenticationStatus } from '_constants/authentication-status';
import TextField from 'shared/TextField/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
import RegisterFormContainer from '../RegisterPage/RegisterFormContainer';
import VKAuthButton from '../../../components/VKAuthButton/VKAuthButton';
import Link from '../../../shared/Link/Link';
import { setAppState } from '../../../_redux/appSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '_redux/store';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    maxWidth: '680px',
    padding: '32px 24px',
    backgroundColor: 'white',
    borderRadius: '24px',
    marginRight: 'auto',

    '& button': {
      fontSize: '16px',
      '&:disabled': {
        color: 'white',
      },
    },

    [xs]: {
      padding: '24px 16px',
      maxWidth: 'initial',
    },
  },
  title: {
    fontFamily: 'Grandis Extended',
    fontSize: 34,
    fontWeight: 900,
    order: 1,
    color: '#313131',
    marginBottom: 24,
    textTransform: 'uppercase',
    [xs]: {
      fontSize: 24,
    },
  },
  form: {
    display: 'grid',
    gap: 24,
    paddingBottom: 24,
    order: 2,
    [xs]: {
      order: 3,
    },
  },
  promptText: {
    color: '#808080',
    fontSize: 18,
    fontFamily: 'Inter',
    order: 3,

    '& a': {
      color: '#313131',
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  passwordTitle: {
    display: 'flex',
    justifyContent: 'space-between',

    '& a': {
      fontSize: 16,
      color: 'var(--clr-red)',
      textDecoration: 'none',
    },
  },
  nextButton: {
    marginBottom: '-8px',
  },
}));

export const LoginPage = () => {
  const classes = useStyles();
  const { login } = useContext(AuthContext);
  const { register } = useSelector((root: RootState) => root);
  const { vkIdKey } = register;
  const { clearTests } = useContext(LkContext);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object().shape({
      email: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: () => handleSubmit(),
  });

  const navigate = useNavigate();

  const handleSubmit = async () => {
    const authData = vkIdKey
      ? {
          email: formik.values.email,
          password: formik.values.password,
          vk_id_key: vkIdKey,
        }
      : {
          email: formik.values.email,
          password: formik.values.password,
        };

    const { data } = await doRequest(login, authData);

    if (data?.token) {
      dispatch(setAppState({ authenticationStatus: AuthenticationStatus.AUTHORIZED }));
      navigate(urlParams.get('returnUrl') || '/lk/profile');
      clearTests();
    }
  };

  return (
    <RegisterFormContainer className={classes.root}>
      <h1 className={classes.title}>Авторизация</h1>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField name={'email'} formik={formik} label={'Электронная почта'} />
        <TextField
          name={'password'}
          type={'password'}
          formik={formik}
          label={
            <div className={classes.passwordTitle}>
              <span>Пароль</span>
              <Link to={'/auth/forgot'}>Забыли пароль?</Link>
            </div>
          }
        />

        <Button
          fullWidth
          size={'small'}
          type='submit'
          disabled={!formik.isValid}
          className={classes.nextButton}
        >
          Продолжить
        </Button>
        <VKAuthButton size={'small'} />
      </form>

      <Box display={'flex'} justifyContent={'center'} className={classes.promptText}>
        <Link to={'/auth/signup'} keepQuery>
          Регистрация
        </Link>
      </Box>
    </RegisterFormContainer>
  );
};

export default LoginPage;
