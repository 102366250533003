import React from 'react';
import TextField from 'shared/TextField';
import { makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { xs } from 'shared/themeInstance';
import RegisterFormContainer from 'pages/AuthPage/RegisterPage/RegisterFormContainer';
import Button from 'shared/Button/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '32px 24px',
    backgroundColor: 'white',
    borderRadius: '24px',
    [xs]: {
      padding: '24px 16px',
    },
  },
  title: {
    fontFamily: 'Grandis Extended',
    fontSize: 34,
    fontWeight: 900,
    color: '#313131',
    marginBottom: '16px',
    textTransform: 'uppercase',
    [xs]: {
      fontSize: 24,
    },
  },
  promptText: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#313131',
    marginBottom: '24px',
  },
  form: {
    '& div': {
      borderRadius: '8px',
    },
    '& button': {
      fontSize: '16px',
      '&:disabled': {
        color: 'white',
      },
    },
    '& .titleFieldSet': {
      marginBottom: '8px',
    },
  },
  code: {
    "&[data-notempty='true'] > div": {
      background: 'initial',
    },
  },
  promptsContainer: {
    '& span': {
      fontSize: '16px',
    },
  },
  textButton: {
    fontSize: '16px',
    fontWeight: 500,
    cursor: 'pointer',
    color: '#BCBCBC',
  },
  textButtonDisabled: {
    color: '#BCBCBC',
  },
  actions: {
    '& button': {
      minWidth: '100%',
      height: '40px',
      marginTop: '24px',
    },
  },
  incorrectEmailText: {
    fontFamily: 'Inter',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '19px',
    color: '#ef4444',
    marginBottom: '8px',
  },
}));

const RegisterStepEmailConfirm = (props) => {
  const {
    formik,
    emailRequestAttemptCount,
    maxEmailRequestAttempts,
    codeSubmitAttemptCount,
    remainingTime,
    onCodeRequest,
  } = props;

  const classes = useStyles();

  return (
    <RegisterFormContainer className={classes.root}>
      <h1 className={classes.title}>Регистрация</h1>
      <p className={classes.promptText}>
        На указанную электронную почту было выслано письмо с кодом для подтверждения
      </p>

      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
          code
          fullWidth
          name={'code'}
          label={'Код подтверждения'}
          formik={formik}
          className={classes.code}
        />

        <div className={classes.promptsContainer}>
          {emailRequestAttemptCount === maxEmailRequestAttempts && (
            <span>Вы исчерпали максимальное количество отправок кода</span>
          )}

          {emailRequestAttemptCount < maxEmailRequestAttempts && (
            <>
              {codeSubmitAttemptCount > 0 && (
                <p className={classes.incorrectEmailText}>Неверный код</p>
              )}
              <p>
                <span
                  className={classnames(classes.textButton, {
                    [classes.textButtonDisabled]: remainingTime > 0,
                  })}
                  onClick={onCodeRequest}
                >
                  Отправить код повторно{' '}
                  {remainingTime > 0 && <span>({remainingTime / 1000})</span>}
                </span>
              </p>
            </>
          )}
        </div>

        <div className={classes.actions}>
          <Button fullWidth type={'submit'} disabled={!formik.isValid}>
            Продолжить
          </Button>
        </div>
      </form>
    </RegisterFormContainer>
  );
};

export default RegisterStepEmailConfirm;
